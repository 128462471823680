"use strict";

// Album Page Toggle to Hide/Show Specs

$(document).ready(function() {

	$('#patterninfobox').hide();
	$('#patternspecs').click(function() {
		$('#patterninfobox').slideToggle(400);
		$(this).text($(this).text() == 'Show Pattern Specs' ? 'Hide Pattern Specs' : 'Show Pattern Specs');
		return false;
	});
});

// Smooth Scrolling for Technical Info Page

$(document).ready(function(){
	$('aside a[href^="#"]').on('click',function (e) {
		e.preventDefault();

		var target = this.hash;
		var $target = $(target);

		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 900, 'swing', function () {
			target.attr('tabindex', '-1');
			target.focus();
		});
	});
});

$(document).ready(function(){
	$('#content a[href^="#"]').on('click',function (e) {
		e.preventDefault();

		var target = this.hash;
		var $target = $(target);

		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 900, 'swing', function () {
			target.attr('tabindex', '-1');
			target.focus();
		});
	});
});

// Scroll-to-Top Functionality

$(document).ready(function(){

	// hide 
	$("#scrolltop").hide();

	// fade in 
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 1600) {
				$('#scrolltop').fadeIn();
			} else {
				$('#scrolltop').fadeOut();
			}
		});

		// scroll body to 0px on click
		$('#scrolltop a[href^="#"]').on('click',function (e) {
			e.preventDefault();

			var target = this.hash;
			var $target = $(target);

			$('html, body').stop().animate({
				'scrollTop': $target.offset().top
			}, 900, 'swing', function () {
				target.attr('tabindex', '-1');
				target.focus();
			});
		});
	});
});

// Jquery Mmenu Controls

$(document).ready(function() {
	$("#sidemenu").mmenu({
		// options
		"slidingSubmenus": true,
		extensions: ["pagedim-black", "pageshadow", "effect-listitems-drop"]
	}, {
		// configuration
		offCanvas: {
			pageNodetype: "div"
		},
		backButton: {
			close: true
		},
		clone: true
		
	});
	var API = $("#sidemenu").data( "mmenu" );

	$("#menubuttonbox").click(function() {
		API.open();
	});
});

// Colorbox 

$(document).ready(function(){
	$("a.thickbox").colorbox({
		maxWidth:"98%", 
		maxHeight:"98%",
		close: 'close'
	});
});


var jRes = jRespond([
	{
		label: 'handheld',
		enter: 0,
		exit: 648
	}
]);

jRes.addFunc({
	breakpoint: 'handheld',
	enter: function() {
		$("#productsmenu").hide();
		$("#productsbutton").click(function(){
			$("#productsmenu").slideToggle();
		});
	},
	exit: function() {
		$("#productsmenu").show();
		$("#productsbutton").unbind("click");
	}
});


	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
													})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-15806945-1', 'auto');
ga('send', 'pageview');